import 'swiper/css/bundle';
import '../sass/app-toolbox.scss';

import { createApp } from 'vue';
import App from './vue/app.js';
import { store } from './vue/vueStore';
import FilterComponent from './vue/components/toolbox/filterComponent.vue';

import './components/menu';
import './components/tabs';
import './components/slider';

// Google Maps
window.initMap = function() {}

// Vue
const app = createApp(App);
app.use(store);
app.component('filter-component', FilterComponent);

window.addEventListener('load', function () {
    if (document.querySelector('#filterbar')) {
        var filters = app.mount('#filterbar');
    }
});
