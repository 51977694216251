<template>
    <div>
        <nav
            class="navbar filterbar"
            role="filter"
            aria-label="dropdown filter"
        >
            <div class="navbar-start">
                <div class="navbar-item">
                    <div class="select">
                        <select v-model="selectedInstrument">
                            <option :value="0"
                                >- Selecteer een instrument -</option
                            >
                            <option
                                v-for="item in selectableInstruments"
                                :value="item.id"
                                >{{ item.title }}</option
                            >
                        </select>
                    </div>
                </div>

                <div class="navbar-item">
                    <div class="select">
                        <select v-model="selectedRegion">
                            <option :value="0">- Selecteer een regio -</option>
                            <option
                                v-for="item in selectableRegions"
                                :value="item.id"
                                >{{ item.title }}</option
                            >
                        </select>
                    </div>
                </div>
            </div>

            <div class="navbar-end">
                <div
                    @click="showMap = false"
                    :class="!showMap ? 'navbar-item is-active' : 'navbar-item'"
                    id="listViewBtn"
                >
                    <span class="icofont-navigation-menu"></span> Lijst
                </div>

                <div
                    @click="showMap = true"
                    :class="showMap ? 'navbar-item is-active' : 'navbar-item'"
                    id="mapViewBtn"
                >
                    <span class="icofont-location-pin"></span> Kaart
                </div>
            </div>
        </nav>

        <div :class="!showMap ? 'is-active' : ''" id="listView">
            <div class="columns is-multiline">
                <div v-for="item in exampleProjects" class="column is-4">
                    <div class="block-wrapper">
                        <div class="block-image" :style="getStyle(item.img)">
                            <a :href="item.itemurl"></a>
                        </div>

                        <div class="block-content">
                            <h3 class="title">{{ item.title }}</h3>
                            <p>Gemeente {{ item.regionname }}</p>

                            <a :href="item.itemurl" class="url-arrow-right"
                                >Meer info</a
                            >
                        </div>
                    </div>
                </div>

                <!-- <div class="column is-hidden-tablet">-->
                <!--    <div class="has-text-centered">-->
                <!--        <button class="button is-primary">Toon meer voorbeeldprojecten</button>-->
                <!--    </div>-->
                <!-- </div>-->
            </div>
        </div>

        <div id="mapView" :class="showMap ? 'is-active' : ''">
            <div class="columns">
                <div class="column">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        instruments: {},
        regions: {},
        examples: {}
    },
    data() {
        return {
            exampleProjects: {},
            selectableInstruments: {},
            selectableRegions: {},
            selectedInstrument: null,
            selectedRegion: null,
            map: null,
            showMap: false,
            mapMarkers: {}
        };
    },
    watch: {
        selectedInstrument: function(value) {
            this.filter("instrument");
            localStorage.setItem("toolbox.filter.instrument", value);
        },
        selectedRegion: function(value) {
            this.filter("region");
            localStorage.setItem("toolbox.filter.region", value);
        }
    },
    methods: {
        getStyle: function(img) {
            return "background-image: url('" + img + "');";
        },
        filterInstrumentSelect: function() {
            if (this.selectedRegion === null) {
                this.selectableInstruments = this.instruments;
            } else {
                this.selectableInstruments = this.instruments.filter(el => {
                    return this.exampleProjects.find(ep => {
                        return ep.instruments.find(instr => {
                            return instr === el.id;
                        });
                    });
                });
            }
        },
        filterRegionSelect: function() {
            if (this.selectedInstrument === null) {
                this.selectableRegions = this.regions;
            } else {
                this.selectableRegions = this.regions.filter(el => {
                    return this.exampleProjects.find(ep => {
                        return ep.regionid === el.id;
                    });
                });
            }
        },
        filter: function(filteredOn) {
            /* filtering by looping through all the projects */
            this.exampleProjects = this.examples.filter(el => {
                var metIntrumentFilterCondition = true;
                var metRegionFilterCondition = true;

                if (this.selectedInstrument) {
                    metIntrumentFilterCondition = el.instruments.some(i => {
                        return i === this.selectedInstrument;
                    });
                }

                if (this.selectedRegion) {
                    metRegionFilterCondition =
                        el.regionid === this.selectedRegion;
                }

                if (metIntrumentFilterCondition && metRegionFilterCondition) {
                    this.addMarker(
                        el.id,
                        el.location.longitude,
                        el.location.latitude
                    );
                    return true;
                }

                this.removeMarker(el.id);
                return false;
            });

            if (filteredOn === "instrument") {
                this.filterRegionSelect();
            }
            if (filteredOn === "region") {
                this.filterInstrumentSelect();
            }
        },
        plotMarkers: function() {
            // Hier afhangen of dit in Toolbox of DGM is voor marker
            var customIcon = "/img/toolbox/marker-toolbox.svg";
            for (var item of this.exampleProjects) {
                var contentString = `<div id="content">
	                    <div id="siteNotice">
		                    <h2 id="firstHeading" class="firstHeading">${item.title}</h2>
                                <div id="bodyContent">
                                    <p>Gemeente ${item.regionname}</p>
                                    <img src="${item.img}" style="height: 200px"/>
                                    <h5>
                                        <a style="font-size:15px;" href="${item.itemurl}"
                                            >Bekijk project</a
                                        >
                                    </h5>
                                </div>
                            </div>
                        </div>`;

                const marker = new google.maps.Marker({
                    position: {
                        lat: item.location.latitude,
                        lng: item.location.longitude
                    },
                    icon: customIcon,
                    map: this.map,
                    title: item.title,
                    animation: google.maps.Animation.DROP
                });

                const infowindow = new google.maps.InfoWindow({
                    content: contentString
                });

                marker.addListener("click", function() {
                    infowindow.open(marker.get("map"), marker);
                });

                this.mapMarkers[item.id] = marker;
            }
        },
        addMarker: function(exampleId, longitude, latitude) {
            this.mapMarkers[exampleId].setMap(null);
            this.mapMarkers[exampleId].setAnimation(google.maps.Animation.DROP);
            this.mapMarkers[exampleId].setMap(this.map);
        },
        removeMarker: function(exampleId) {
            this.mapMarkers[exampleId].setMap(null);
        },
        getLocalStorageValue: function(key) {
            if (localStorage.getItem(key)) {
                return localStorage.getItem(key);
            }
            return null;
        }
    },
    mounted() {
        /* Copy props to data, since we should never change props */
        this.exampleProjects = this.examples;
        this.selectableInstruments = this.instruments;
        this.selectableRegions = this.regions;

        /* if we used a filter before, the last knows value was stored. Get it, set it */
        this.selectedInstrument = Number(
            this.getLocalStorageValue("toolbox.filter.instrument")
        );
        this.selectedRegion = Number(
            this.getLocalStorageValue("toolbox.filter.region")
        );

        /* Initialize the map */
        this.map = new google.maps.Map(document.getElementById("map"), {
            zoom: 7,
            streetViewControl: false,
            mapTypeId: "terrain",
            mapTypeControl: false,
            center: { lat: 52.185852, lng: 5.270391 }
        });

        /* Plot the inital markers on the map */
        this.plotMarkers();
    }
};
</script>
